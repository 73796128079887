<template>
  <b-col id="profile-content" cols="12">
    <b-row no-gutters>
      <ChangeUserInfo
        v-if="this.$store.getters['user/currentUser'].is_full_account"
        :stats="stats"
        :name-change-cost="nameChangeCost"
        @handleUsernameChange="changeUsername"
        @handleEmailChange="changeEmail"
        @handleBirthdayChange="changeBirthday"
        @handleNewsletterChange="changeNewsletter"
        @handlePasswordChange="changePassword"
        @handleEmailConfirm="confirmEmail"
      />
      <ManageGuestAccount v-else :user="user" />
      <div class="golden-divider"></div>
    </b-row>
  </b-col>
</template>

<script>
import ChangeUserInfo from "@/components/profile/change-user-info.vue";
import ManageGuestAccount from "@/components/profile/manage-guest-account.vue";
import * as profileAPI from "@/API/profile.js";
import AnimationFullRewardEarned from "@/components/animation/animation-full-reward-earned";

export default {
  name: "ProfileContainer",
  components: {
    ChangeUserInfo,
    ManageGuestAccount,
  },
  mixins: [AnimationFullRewardEarned],
  props: {
    stats: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      usernames: [],
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/currentUser"];
    },
    nameChangeCost() {
      if (this.usernames.length > 0) {
        return this.$store.state.generalConfig.settings[
          "user.name.change.price"
        ];
      } else {
        return 0;
      }
    },
    newsletterGold() {
      let settings = this.$store.state.generalConfig.settings;
      if (settings["user.newsletter.gold"]) {
        return settings["user.newsletter.gold"];
      }

      return 100000000;
    },
  },
  mounted() {
    if (this.user) {
      this.$store.commit("setIsPageLoading", false);
    }
    this.updateUsernameHistory();
  },
  methods: {
    changeNewsletter() {
      if (this.user.newsletter) {
        profileAPI
          .unsubscribeNewsletter()
          .then(() => {
            this.$store.commit("popups/setAlertBannerContent", {
              alertHeading: this.$t("alerts.title.success"),
              alertText: this.$t("profile.newsletter.unsubscribed"),
              type: "check",
            });
            this.firebaseClickItemProfile(
              "profile_receive_newsletter_no_button"
            );
            this.$emit("update");
          })
          .catch((e) => {
            console.log(e);
            this.displayErrorMessage();
          });
      } else {
        profileAPI
          .subscribeNewsletter()
          .then(() => {
            this.$store.commit("popups/setAlertBannerContent", {
              alertHeading: this.$t("alerts.title.success"),
              alertText: this.$t("profile.newsletter.subscribed"),
              type: "check",
            });
            this.firebaseClickItemProfile(
              "profile_receive_newsletter_yes_button"
            );

            //show reward show for first newsletter sub
            if (!this.user.newsletter_at) {
              let rewards = [
                {
                  reward: "gold",
                  amount: this.formatNumber(this.newsletterGold),
                },
              ];
              this.AnimationFullRewardEarnedAnimate(rewards, false);
            }

            this.$emit("update");
          })
          .catch((e) => {
            console.log(e);
            this.displayErrorMessage();
          });
      }
    },
    async changeUsername(newName) {
      await profileAPI
        .updateUsername(newName)
        .then((res) => {
          this.newUsername = res.data.data.user.name;
          this.$store.commit("popups/setAlertBannerContent", {
            alertText: this.$t("profile.change_username.success", {
              0: this.newUsername,
            }),
            alertHeading: this.$t("alerts.title.success"),
            type: "check",
          });
          this.$store.commit("user/updateUserObject", res.data.data.user);
          this.updateUsernameHistory();
        })
        .catch((e) => {
          if (e.data.message) {
            this.$store.commit("popups/setAlertBannerContent", {
              alertText: e.data.message,
              alertHeading: this.$t("alerts.title.error"),
              type: "danger",
              variant: "danger",
            });
          } else {
            this.displayErrorMessage();
          }
        });
    },
    async changeEmail(newEmailAdress) {
      let localeQuery =
        this.$store.getters.getLocale +
        "_" +
        this.$store.getters.getLocale.toUpperCase();
      await profileAPI
        .updateEmail(newEmailAdress, localeQuery)
        .then((res) => {
          this.$store.commit("popups/setAlertBannerContent", {
            alertText: this.$t("profile.email_confirm.alert", {
              0: newEmailAdress,
            }),
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.data.details) {
            this.$store.commit("popups/setAlertBannerContent", {
              alertText: e.data.details[0],
              alertHeading: this.$t("alerts.title.error"),
              type: "danger",
              variant: "danger",
            });
          } else {
            this.displayErrorMessage();
          }
        });
    },
    async changeBirthday(birthday) {
      await profileAPI
        .storeBirthday(birthday)
        .then((res) => {
          this.$store.commit("popups/setAlertBannerContent", {
            alertText: this.$t("profile.change_birthday.success"),
            alertHeading: this.$t("alerts.title.success"),
            type: "check",
          });
          this.$store.commit("user/updateUserObject", res.data.data.user);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async changePassword(oldPassword, newPassword) {
      await profileAPI
        .updatePassword(oldPassword, newPassword)
        .then((res) => {
          this.$store.commit("popups/setAlertBannerContent", {
            alertText: this.$t("profile.change_password.success"),
            alertHeading: this.$t("alerts.title.success"),
            type: "check",
          });
        })

        .catch((error) => {
          if (error.status === 422) {
            this.$store.commit("popups/setAlertBannerContent", {
              alertText: this.$t("alert.wrong_password"),
              alertHeading: this.$t("alerts.title.error"),
              type: "danger",
              variant: "danger",
            });
          } else {
            this.displayErrorMessage();
          }
        });
    },

    confirmEmail() {
      profileAPI
        .confirmEmail()
        .then((res) => {
          if (res.data.status === "error") {
            this.errorMessage = this.$t(res.data.message);
            this.$store.commit("popups/setAlertBannerContent", {
              alertText: this.errorMessage,
              alertHeading: this.$t("alerts.title.error"),
              type: "danger",
              variant: "danger",
            });
            return;
          }

          this.$store.commit("popups/setAlertBannerContent", {
            alertText: this.$t("profile.email_confirm.alert", {
              0: this.$store.getters["user/currentUser"].email,
            }),
          });
        })
        .catch((e) => {
          if (e.data) {
            let errorMessage;
            //get error message from server
            let errorDetails = e.data.details[0]
              .split(" ")
              .join("_")
              .split(".")
              .join("")
              .toLowerCase();
            if (
              this.$i18n.i18next.exists(
                "profile.email_confirm.error." + errorDetails
              )
            ) {
              errorMessage = this.$t(
                "profile.email_confirm.error." + errorDetails
              );
            } else {
              this.displayErrorMessage();
            }
            //fallback
            this.$store.commit("popups/setAlertBannerContent", {
              alertText: errorMessage,
              alertHeading: this.$t("alerts.title.error"),
              variant: "danger",
              type: "danger",
            });
          }
        });
    },
    updateUsernameHistory() {
      profileAPI
        .getUserNameHistory()
        .then((res) => {
          this.usernames = res.data.data.usernames;
        })
        .catch((e) => console.log(e));
    },
  },
};
</script>
<style lang="scss"></style>
