<template>
  <div id="manage-guest-account" class="container">
    <b-row class="w-50 mx-auto mb-3" align-v="center" align-h="center">
      <b-col
        cols="8"
        class="d-inline-flex align-items-center justify-content-center"
      >
        <div id="avatar-field" class="position-relative">
          <img
            draggable="false"
            alt="avatar"
            class="avatar-img dead-center"
            :src="this.$store.getters['user/currentUser'].avatar_url"
          />
        </div>
        <h2 class="text-white text-uppercase ml-3">{{ user.name }}</h2>
      </b-col>
    </b-row>

    <b-row class="form-row w-50 mx-auto" align-h="center">
      <b-col cols="12" class="section-head-row px-5">
        <span class="uppercase-subheadline">
          {{ $t("profile.register.title") }}
        </span>
        <p class="blue">
          {{ $t("profile.register.subtitle") }}
        </p>
      </b-col>
      <b-col cols="12" class="section-content-row px-5 py-3">
        <form
          id="register-guest-user"
          class="d-flex flex-column align-items-center"
          @submit.prevent="handleGuestRegister"
        >
          <div class="form-group w-100">
            <label class="dark-label">{{ $t("login.your_email") }}</label>
            <input
              v-model="email"
              type="email"
              name="email"
              class="form-control dark"
              :class="{ error: !validEmail }"
              required
              placeholder="name@beispiel.de"
              autocomplete="email"
            />
          </div>
          <div class="form-group w-100">
            <label class="dark-label">{{
              $t("login.web.register.choose_password")
            }}</label>
            <input
              v-model="password"
              name="password"
              type="password"
              class="form-control dark"
              :class="{ error: !minLength }"
              required
              placeholder="*********"
              autocomplete="password"
            />
          </div>
          <button
            role="submit"
            class="w-50 ok-button-green"
            :is-disabled="!minLength || !validEmail"
          >
            {{ $t("profile.register.title") }}
          </button>
        </form></b-col
      ></b-row
    >
  </div>
</template>

<script>
import * as profileAPI from "@/API/profile.js";
export default {
  name: "ManageGuestAccount",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      password: "",
      email: "",
    };
  },
  computed: {
    minLength() {
      return this.password.length >= 6;
    },
    validEmail() {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.email);
    },
  },
  mounted() {
    this.$root.$on("ok-modal-login-prompt", () => {
      this.handleLogin();
    });
  },
  methods: {
    handleGuestRegister() {
      let payload = { email: this.email, password: this.password };
      profileAPI
        .upgradeAccount(payload)
        .then((res) => {
          this.$store.commit("user/updateUserObject", res.data.data.user);
        })
        .catch((e) => {
          if (e.data.id === "email_in_use") {
            this.openAlertWindow(
              "modal-login-prompt",
              this.$t("profile.register.email_in_use.message"),
              true
            );
          } else console.log(e);
        });
    },
    handleLogin() {
      let payload = { email: this.email, password: this.password };
      this.$store
        .dispatch("auth/logUserIn", payload)
        .then((res) => {
          this.$store.commit("user/updateUserObject", res.data.user);
          this.$router.go();
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              this.$store.commit("popups/setAlertBannerContent", {
                alertText: this.$t("alert.wrong_password"),
                alertHeading: this.$t("alerts.title.error"),
                type: "danger",
                variant: "danger",
              });
            }
          } else {
            console.log(err);
            this.displayErrorMessage();
          }
        });
    },
  },
};
</script>
<style lang="scss">
#manage-guest-account {
  height: 100%;
  position: relative;
  padding: 16px;
  .section-head-row {
    height: 64px;
    background-color: $dark-blue-03;
    span {
      &.blue {
        font-size: 16px;
      }
    }
  }
  .section-content-row {
    background-color: $dark-blue-06;
    color: white;
    text-transform: uppercase;
    font-family: Ubuntu-medium;
    font-size: 16px;
  }
  #avatar-field {
    width: 160px;
    height: 160px;
    overflow: hidden;
    border-radius: 10%;
    background-color: black;
    box-shadow: 0 3px 0 #5a1f1f;

    .avatar-img {
      max-height: 100%;
    }
  }
}
</style>
