<!-- MODAL: CHANGE PASSWORD -->
<template>
  <b-modal
    id="modal-change-password"
    modal-class="input-window"
    :hide-footer="true"
  >
    <template slot="modal-header">
      <DecoCorners color-variant="blue" />

      <h3 class="super-headline3-light">
        {{ $t("profile.change_password") }}
      </h3>
      <div
        role="button"
        href="#"
        class="close-button"
        @click="handleCancel"
      ></div>
    </template>
    <template slot="default">
      <b-form class="w-75 mx-auto" @submit.prevent="handleConfirm">
        <label for="current-password" class="dark-label">{{
          $t("profile.change_password.input_current_password")
        }}</label>
        <input
          id="current-password"
          v-model="oldPassword"
          type="password"
          class="form-control dark mb-3"
          autocomplete="current-password"
          :class="{ error: oldPasswordError }"
          required
          @blur="checkOldPassword"
        /><label for="new-password" class="dark-label">
          {{ $t("profile.change_password.input_new_password") }}</label
        ><input
          id="new-password"
          v-model="newPassword"
          type="password"
          class="form-control dark"
          :class="{ error: minLengthError }"
          autocomplete="new-password"
          @blur="checkPasswordLength"
        />
        <p
          v-if="minLengthError"
          class="w-100 small m-0 text-center text-danger"
        >
          {{ $t("profile.change_password.invalid_feedback.length", { 0: 6 }) }}
        </p>
        <label for="password-confirm" class="dark-label">
          {{ $t("profile.change_password.confirm_new_password") }}</label
        >

        <input
          id="password-confirm"
          v-model="newPasswordConfirm"
          type="password"
          class="form-control dark"
          :class="{ error: minLengthError || pwMatchError }"
          autocomplete="new-password"
          @blur="checkNewPassword"
        />
        <p v-if="pwMatchError" class="w-100 small m-0 text-center text-danger">
          {{ $t("profile.change_password.invalid_feedback.match") }}
        </p>

        <div class="d-flex justify-content-between mb-3 mt-3">
          <button
            :disabled="isDisabled"
            type="submit"
            class="ok-button-green mr-3"
            :class="{ disabled: isDisabled }"
          >
            OK
          </button>
          <button class="cancel-button-red" @click="handleCancel">
            {{ $t("profile.change_password.cancel") }}
          </button>
        </div></b-form
      >
    </template>
  </b-modal>
</template>
<script>
import DecoCorners from "@/components/base/deco-corners.vue";
export default {
  name: "ModalChangePassword",
  components: { DecoCorners },
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
      minLengthError: false,
      pwMatchError: false,
      oldPasswordError: false,
    };
  },
  computed: {
    isDisabled() {
      return this.minLengthError || this.pwMatchError || this.oldPasswordError;
    },
  },
  methods: {
    handleConfirm() {
      this.$emit("passwordChanged", this.oldPassword, this.newPassword);
      this.clearInputs();
      this.$bvModal.hide("modal-change-password");
    },
    handleCancel() {
      this.clearInputs();
      this.$bvModal.hide("modal-change-password");
    },
    clearInputs() {
      this.oldPassword = "";
      this.newPassword = "";
      this.newPasswordConfirm = "";
      this.minLengthError = false;
      this.pwMatchError = false;
      this.oldPasswordError = false;
    },
    checkPasswordLength() {
      if (this.newPassword.length < 6) {
        this.minLengthError = true;
      } else {
        this.minLengthError = false;
      }
    },
    checkOldPassword() {
      if (this.oldPassword.length < 6) {
        this.oldPasswordError = true;
      } else {
        this.oldPasswordError = false;
      }
    },
    checkNewPassword() {
      if (this.newPassword !== this.newPasswordConfirm) {
        this.pwMatchError = true;
      } else {
        this.pwMatchError = false;
      }
    },
  },
};
</script>
<style lang="scss">
.input-window {
  .modal-header {
    border: none !important;
    flex-wrap: wrap;
    justify-content: center;
    .close-button {
      position: absolute;
      right: -16px;
      top: -16px;
      width: 32px;
      height: 32px;
    }
  }
  .modal-content {
    color: white;
    text-align: center;
    background-color: transparent;
    width: 100%;
    background-image: linear-gradient(#205f72, #050d1c),
      linear-gradient(#050d1c, #205f72);
    border: double 1px transparent;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-size: 100% 100%;
    min-height: 128px;
  }
  .modal-body {
    padding: 0;
  }
  .modal-footer {
    border-top: 0;
    justify-content: center;
  }

  .modal-buttons {
    justify-content: space-around;
    width: 80%;
  }
}
</style>
