<template>
  <div v-if="user" id="change-user-info" class="container">
    <b-row class="h-100">
      <b-col cols="6">
        <b-row no-gutters align-v="center" class="section-head-row pl-3">
          <span class="uppercase-subheadline">{{
            $t("profile.head_row.personal_data")
          }}</span>
        </b-row>
        <b-row no-gutters class="section-content-row pl-3">
          <b-col cols="4" class="light-yellow py-3">{{
            $t("profile.username")
          }}</b-col>
          <b-col cols="8" class="user-info-edit"
            ><template v-if="!nameEditMode"
              ><span>{{ user.name }}</span>
              <div class="edit-pen ml-3" @click="nameEditMode = true"></div
            ></template>

            <!-- FORM INPUT: CHANGE USERNAME -->
            <template v-else>
              <form @submit.prevent="changeUsername">
                <b-input-group>
                  <input
                    id="username-input"
                    v-model="newUsername"
                    maxlength="40"
                    class="mb-1 dark-input gradient-indent"
                  />
                  <b-input-group-append>
                    <div
                      class="append cancel gradient-indent"
                      name="username-append"
                      @click="cancelChangeUsername"
                    >
                      <img
                        draggable="false"
                        class="append-symbol dead-center"
                        src="@/assets/img/profile/cancel.svg"
                      />
                    </div>
                    <div
                      class="append save gradient-indent"
                      :class="{ disabled: !correctLength }"
                      @click="changeUsername"
                    >
                      <img
                        draggable="false"
                        class="append-symbol dead-center"
                        src="@/assets/img/common/input-field-checkmark.svg"
                      />
                    </div>
                  </b-input-group-append>
                </b-input-group></form
            ></template>
          </b-col>

          <b-col cols="4" class="light-yellow py-3">
            {{ $t("profile.birthday") }}</b-col
          ><b-col cols="8" class="user-info-edit">
            <span v-if="birthdaySaved">
              {{ birthdayLocale }}
            </span>
            <div
              v-else-if="!birthdaySaved && !birthdayEditMode"
              class="edit-pen ml-1"
              @click="editBirthday"
            ></div>

            <form
              v-if="!birthdaySaved && birthdayEditMode"
              @submit.prevent="handleBirthdaySave"
            >
              <b-input-group>
                <input
                  id="birthday-input"
                  v-model="userBirthday"
                  type="date"
                  class="mb-1 dark-input gradient-indent"
                />

                <b-input-group-append>
                  <div
                    class="append cancel gradient-indent"
                    @click="cancelBirthdayEdit"
                  >
                    <img
                      draggable="false"
                      class="append-symbol dead-center"
                      src="@/assets/img/profile/cancel.svg"
                    />
                  </div>
                  <div
                    class="append save gradient-indent"
                    @click="handleBirthdaySave"
                  >
                    <img
                      draggable="false"
                      class="append-symbol dead-center"
                      src="@/assets/img/common/input-field-checkmark.svg"
                    />
                  </div>
                </b-input-group-append>
                <p v-if="ageAlert" class="text-red small">
                  {{ $t("profile.birthday.invalid_feedback.age") }}
                </p>
              </b-input-group>
            </form>
          </b-col>
          <b-col cols="4" class="light-yellow py-3">{{
            $t("profile.member_since")
          }}</b-col>
          <b-col cols="8" class="user-info-edit">{{ memberSince }}</b-col>
          <!-- LEFT FIELD LEFT COLUMN: AVATAR AND NEWSLETTER -->

          <!-- <b-col
        id="avatar-col"
        cols="3"
        class="h-100 d-flex flex-column justify-content-around"
      >
        <div
          id="avatar-field"
          class="gradient-indent glow position-relative"
          @click="$emit('handleAvatarClick')"
        >
          <img
            draggable="false"
            alt="avatar"
            class="avatar-img dead-center"
            :src="this.$store.getters['user/currentUser'].avatar_url"
          />
          <div id="edit-field" class="to-center-abs text-center blue">
            {{ $t("profile.avatar.edit") }}
          </div>
        </div>
        <div id="user-id-field" class="p-1 text-center">
          <span class="text-uppercase text-white bold">User-ID </span
          ><span class="blue">{{ $store.getters["user/currentUser"].id }}</span>
        </div>

        </div>
      </b-col> -->

          <!-- TOP CENTER COLUMN: USERNAME, EMAIL, BIRTHDAY  -->
        </b-row></b-col
      >
      <b-col cols="6">
        <b-row no-gutters align-v="center" class="section-head-row pl-3">
          <span class="uppercase-subheadline">{{
            $t("profile.head_row.login")
          }}</span>
        </b-row>
        <b-row no-gutters align-v="center" class="section-content-row pl-3">
          <b-col cols="4" class="py-3 light-yellow">{{
            $t("profile.email")
          }}</b-col>
          <b-col cols="8" class="user-info-edit"
            ><template v-if="!emailEditMode"
              ><span>{{ user.email }}</span>
              <div
                class="edit-pen ml-3"
                @click="emailEditMode = true"
              ></div></template
            ><template v-else>
              <b-form class="w-100" @submit.prevent="changeEmail">
                <b-input-group>
                  <input
                    id="email-input"
                    v-model="newEmailAdress"
                    class="mb-1 dark-input gradient-indent w-75"
                  />
                  <b-input-group-append class="w-25">
                    <div
                      class="append cancel gradient-indent"
                      @click="cancelChangeEmail"
                    >
                      <img
                        draggable="false"
                        class="append-symbol dead-center"
                        src="@/assets/img/profile/cancel.svg"
                      />
                    </div>
                    <div
                      class="append save gradient-indent"
                      @click="changeEmail"
                    >
                      <img
                        draggable="false"
                        class="append-symbol dead-center"
                        src="@/assets/img/common/input-field-checkmark.svg"
                      />
                    </div>
                  </b-input-group-append>
                </b-input-group> </b-form></template></b-col
          ><b-col cols="4" class="light-yellow py-3">
            {{ $t("profile.email_confirmed") }} </b-col
          ><b-col cols="8" class="user-info-edit">
            <div
              class="check-icn bg-img"
              :class="{ off: !emailConfirmed }"
            ></div>
            <div
              v-if="!emailConfirmed"
              class="edit-pen ml-3"
              @click="$emit('handleEmailConfirm')"
            ></div
          ></b-col>
          <b-col cols="4" class="light-yellow py-3">{{
            $t("profile.password")
          }}</b-col>
          <b-col cols="8" class="user-info-edit"
            ><span>***********</span>
            <div
              class="edit-pen ml-3"
              @click="$bvModal.show('modal-change-password')"
            >
              <ModalChangePassword @passwordChanged="emitPassword" /></div
          ></b-col> </b-row
      ></b-col>
      <b-col cols="6" class="mt-5">
        <b-row no-gutters align-v="center" class="section-head-row pl-3">
          <span class="uppercase-subheadline">Newsletter</span>
        </b-row>
        <b-row no-gutters align-v="center" class="section-content-row pl-3">
          <b-col cols="9" class="light-yellow py-3">
            <div v-if="user.newsletter_at" class="text-white mb-1">
              {{ $t("profile.newsletter") }}
            </div>
            <i18next
              v-else
              class="text-white mb-1"
              path="profile.newsletter.text_new"
              tag="span"
            >
              <span class="bold" place="0">
                {{ newsletterGold }}
              </span>
            </i18next>
          </b-col>

          <b-col cols="3" class="user-info-edit"
            ><div
              class="check-icn bg-img ml-3"
              :class="{ off: !newsletterSubscribed }"
            ></div>
            <div
              class="edit-pen ml-3"
              @click="$emit('handleNewsletterChange')"
            ></div
          ></b-col> </b-row
      ></b-col>
    </b-row>
  </div>
</template>

<script>
import ModalChangePassword from "@/components/profile/modal-change-password.vue";

export default {
  name: "ChangeUserInfo",
  components: { ModalChangePassword },
  props: {
    nameChangeCost: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      nameEditMode: false,
      emailEditMode: false,
      birthdayEditMode: false,
      newEmailAdress: "",
      newUsername: "",
      ageAlert: false,
      userBirthday: "",
      userNewsletter: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/currentUser"];
    },
    birthdaySaved() {
      if (this.$store.getters["user/currentUser"].dateOfBirth) {
        return true;
      } else {
        return false;
      }
    },
    ageCheck() {
      return this.calculateAge() >= 18;
    },
    ageFormatted() {
      let regexp =
        /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/;
      return regexp.test(this.userBirthday);
    },

    birthdayLocale() {
      if (this.$store.getters["user/currentUser"].dateOfBirth) {
        let birthday = new Date(
          this.$store.getters["user/currentUser"].dateOfBirth
        );
        return birthday.toLocaleDateString(this.$store.state.locale, {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        });
      } else return null;
    },
    memberSince() {
      let createdAt = new Date(
        this.$store.getters["user/currentUser"].created_at
      );
      return createdAt.toLocaleDateString(this.$store.state.locale, {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
    },
    correctLength() {
      return this.newUsername.length >= 3 && this.newUsername.length <= 40;
    },
    newsletterSubscribed() {
      return this.$store.getters["user/currentUser"].newsletter;
    },

    newsletterGold() {
      let settings = this.$store.state.generalConfig.settings;
      if (settings["user.newsletter.gold"]) {
        return this.formatNumber(settings["user.newsletter.gold"], true);
      }

      return this.formatNumber(100000000, true);
    },
    emailConfirmed() {
      return this.$store.getters["user/currentUser"].email_verified_at;
    },
  },
  mounted() {
    this.newUsername = this.$store.getters["user/currentUser"].name;
    this.newEmailAdress = this.$store.getters["user/currentUser"].email;
    this.userNewsletter = this.$store.getters["user/currentUser"].newsletter;
    this.$root.$on("ok-modal-set-birthday", () => {
      this.$emit("handleBirthdayChange", this.userBirthday);
    });
    this.$root.$on("ok-name-change-alert", () => {
      this.nameEditMode = true;
    });
  },
  methods: {
    handleEditNameClick() {
      if (this.nameChangeCost === 0) {
        this.nameEditMode = true;
      } else {
        let translatedString;
        if (this.nameChangeCost === 1) {
          translatedString = "profile.username-change-alert>one";
        } else {
          translatedString = "profile.username-change-alert>other";
        }
        this.openAlertWindow(
          "name-change-alert",
          this.$t(translatedString, {
            0: this.nameChangeCost,
          }),
          true
        );
      }
    },

    editBirthday() {
      this.firebaseClickItemProfile("profile_change_birthday_button");
      this.birthdayEditMode = true;
    },
    cancelBirthdayEdit() {
      this.birthdayEditMode = false;
      this.userBirthday = "";
      this.ageAlert = false;
    },
    changeUsername() {
      if (this.newUsername !== this.$store.getters["user/currentUser"].name) {
        this.$emit("handleUsernameChange", this.newUsername);
        this.nameEditMode = false;
        // this reverts to the old username from the store in case of an error
        this.newUsername = this.$store.getters["user/currentUser"].name;
        this.firebaseVirtualCurrencyDrain(
          "username_change",
          "CROWN",
          this.nameChangeCost,
          "PROFILE"
        );
      } else {
        return;
      }
    },
    changeEmail() {
      this.$emit("handleEmailChange", this.newEmailAdress);
      this.emailEditMode = false;
    },
    cancelChangeUsername() {
      this.nameEditMode = false;
      this.newUsername = this.$store.getters["user/currentUser"].name;
    },
    editEmail() {
      this.firebaseClickItemProfile("profile_change_email_button");
      this.emailEditMode = true;
      this.newEmailAdress = this.$store.getters["user/currentUser"].email;
    },
    cancelChangeEmail() {
      this.emailEditMode = false;
    },
    calculateAge() {
      let now = new Date();
      let userBirthdayDate = new Date(this.userBirthday);
      let birthdayThisYear = new Date(
        now.getFullYear(),
        userBirthdayDate.getMonth(),
        userBirthdayDate.getDate()
      );
      if (now >= birthdayThisYear) {
        return now.getFullYear() - userBirthdayDate.getFullYear();
      } else {
        return now.getFullYear() - userBirthdayDate.getFullYear() - 1;
      }
    },
    handleBirthdaySave() {
      if (this.ageCheck) {
        let birthday = new Date(this.userBirthday);
        let localeBirthday = birthday.toLocaleDateString(
          this.$store.state.locale,
          {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          }
        );
        this.openAlertWindow(
          "modal-set-birthday",
          this.$t("profile.birthday.change_alert", { 0: localeBirthday }),
          true
        );
      } else {
        this.ageAlert = true;
      }
    },
    handleBirthdayInput() {
      this.ageAlert = false;
    },
    emitPassword(oldPassword, newPassword) {
      this.$emit("handlePasswordChange", oldPassword, newPassword);
    },
  },
};
</script>
<style lang="scss">
#change-user-info {
  .section-head-row {
    height: 64px;
    background-color: $dark-blue-03;
    span {
      &.blue {
        font-size: 16px;
      }
    }
  }
  .section-content-row {
    background-color: $dark-blue-06;
    color: white;
    text-transform: uppercase;
    font-family: Ubuntu-medium;
    font-size: 16px;
    .user-info-edit {
      display: inline-flex;
      align-items: center;
    }
    .light-yellow {
      color: #fff8b7;
    }
  }
  // #avatar-field {
  //   cursor: pointer;
  //   border-radius: 50%;
  //   width: 160px;
  //   height: 160px;
  //   overflow: hidden;

  //   &:hover {
  //     filter: $hover-shadow;
  //   }

  //   #edit-field {
  //     width: 107px;
  //     height: 24px;
  //     background: url(~@/assets/img/profile/edit-field.svg) center/100% 100%
  //       no-repeat;
  //     bottom: -2px;
  //     font-size: 12px;
  //     -webkit-transform: translate3d(0, 0, 0);
  //   }

  //   .avatar-img {
  //     max-height: 100%;
  //   }
  // }

  .dark-input {
    border-radius: 8px 0 0 8px;
    color: $light-blue;
    font-size: 16px;
    height: 40px;
    border: 0;

    &:focus {
      outline: none;
    }
  }

  .append {
    height: 40px;
    width: 40px;
    border-left: 0;
    cursor: pointer;
    position: relative;

    &:hover .append-symbol {
      filter: $hover-shadow;
    }

    &.cancel {
      border-radius: 0;
      border-right: 0;
    }

    &.save {
      border-radius: 0 8px 8px 0;

      &.disabled {
        pointer-events: none;
        filter: grayscale(100);
      }
    }
  }

  .edit-pen {
    width: 16px;
    height: 16px;
    background-image: url(~@/assets/img/common/pencil-edit-button.svg);
    cursor: pointer;
    -webkit-transform: translate3d(0, 0, 0);

    &:hover {
      filter: $hover-shadow;
    }
  }
  .cancel-icn {
    width: 20px;
    height: 20px;
    background-image: url(~@/assets/img/profile/cancel.svg);
    cursor: pointer;
    -webkit-transform: translate3d(0, 0, 0);
  }
  .check-icn {
    width: 20px;
    height: 20px;
    background-image: url(~@/assets/img/common/check-green.png);
    &.off {
      background-image: url(~@/assets/img/events/icn-denied-red.svg);
    }
  }
  #crown-symbol {
    height: 25px;
    margin-top: -10px;
  }

  .stats-item {
    height: 38px;
    border-radius: 21px;
    font-size: 18px;

    .text-white,
    .subheadline-golden,
    .blue {
      font-size: 18px;
    }
  }

  legend {
    color: white;
    font-size: 19.2px;
  }

  .email-confirm-button {
    width: 300px;
    height: 45px;
    @media (max-width: 1440px) {
      font-size: 12px;
    }
  }

  .text-red {
    color: #ff5454;
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  }
}
</style>
