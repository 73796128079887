<template>
  <b-col id="stats-container" cols="12">
    <div
      v-for="tile in statTiles"
      :key="tile.id"
      class="stat-tile w-100 d-flex flex-column align-items-center justify-content-between"
    >
      <div
        class="stat-icon-container w-100 mb-3 d-flex flex-column align-items-center justify-content-end"
      >
        <div
          class="stat-icon bg-img"
          :class="[tile.type, $store.state.locale]"
        ></div>
      </div>
      <div class="w-75 text-center mb-2">
        <span class="stat-title text-uppercase text-white bold text-glow">{{
          tile.text
        }}</span>
      </div>

      <div
        class="tile-display w-100 text-center d-flex align-items-center justify-content-center"
      >
        <span class="stat-value text-uppercase text-white bold text-glow"
          ><template v-if="tile.value">{{ tile.value }}</template
          ><template v-else>--</template></span
        >
      </div>
    </div>
  </b-col>
</template>

<script>
export default {
  name: "UserStats",
  components: {},
  props: {
    stats: {
      type: Object,
      required: false,
      default: null,
    },
  },

  computed: {
    statTiles() {
      return [
        {
          id: 1,
          type: "gold",
          text: this.$t("popup_user_details.highest_profit"),
          value: this.slotWinHighest,
        },
        {
          id: 2,
          type: "gold",
          text: this.$t("popup_user_details.highest_jackpot"),
          value: this.jackpotHighest,
        },
        {
          id: 3,
          type: "achievement",
          text: this.$t("popup_user_details.latest_success"),
          value: this.latestAchievement,
        },
        {
          id: 4,
          type: "tournament",
          text: this.$t("popup_user_details.won_tournaments"),
          value: this.wonTournaments,
        },
      ];
    },
    slotWinHighest() {
      if (this.stats.stats && this.stats.stats.slot_win_highest) {
        return this.formatNumber(this.stats.stats.slot_win_highest);
      } else return null;
    },
    jackpotHighest() {
      if (this.stats.stats && this.stats.stats.jackpot_highest) {
        return this.formatNumber(this.stats.stats.jackpot_highest);
      } else return null;
    },
    latestAchievement() {
      if (this.stats.stats && this.stats.stats.achievement_won_last) {
        return this.$t(
          `achievements.achievement_title.${this.stats.stats.achievement_won_last}`
        );
      } else return null;
    },
    wonTournaments() {
      if (this.stats.stats && this.stats.stats.tournament_won_increment) {
        return this.stats.stats.tournament_won_increment;
      } else return null;
    },
  },
  mounted() {
    this.$store.commit("setIsPageLoading", false);
  },
  methods: {},
};
</script>
<style lang="scss">
#stats-container {
  display: grid;
  column-gap: 14px;
  row-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(298px, 1fr));
  grid-auto-rows: minmax(290px, auto);
  max-width: 1500px;
  margin: auto;

  .stat-tile {
    background-image: radial-gradient(#274b60, #0a1d28);
    border-radius: 16px;
    padding: 19px 18px 22px 16px;
    .stat-icon-container {
      height: 90px;
    }
    .stat-icon {
      &.gold {
        width: 140px;
        height: 87px;
        background-image: url(~@/assets/img/profile/stat-icon-gold.png);
      }
      &.achievement {
        width: 68px;
        height: 67px;
        background-image: url(~@/assets/img/profile/stat-icon-achievement.png);
      }
      &.kingspath {
        width: 102px;
        height: 102px;
        background-image: url(~@/assets/img/profile/stat-icon-kingspath_de.png);

        &.en {
          width: 88px;
          background-image: url(~@/assets/img/profile/stat-icon-kingspath_en.png);
        }
      }

      &.tournament {
        width: 125px;
        height: 84px;
        background-image: url(~@/assets/img/profile/stat-icon-tournament_de.png);

        &.en {
          width: 134px;

          background-image: url(~@/assets/img/profile/stat-icon-tournament_en.png);
        }
      }
    }

    .text-glow {
      text-shadow: 0 0 16px rgba(77, 229, 255, 0.6),
        0 0 8px rgba(77, 229, 255, 0.6), 0 0 4px rgba(0, 94, 110, 0.8);
    }
    .stat-title {
      font-size: 24px;
    }
    .tile-display {
      min-height: 99px;
      background-color: black;
      border-radius: 8px;
      box-shadow: 0 3px 0 #074f5c;
    }
    .stat-value {
      font-size: 28px;
    }
  }
}
</style>
