<template>
  <div>
    <b-modal
      v-if="rewards.length > 0"
      id="modal-voucher-redeemed"
      :hide-footer="true"
      modal-class="custom-modal-transparent"
    >
      <template slot="modal-header" slot-scope="{ close }">
        <h1 class="text-uppercase bold">
          {{ $t("profile.voucher.redeem_popup.headline") }}
        </h1>
        <a href="#" class="close-button" @click="close()"> </a>
      </template>

      <template slot="default">
        <h3 class="text-white">
          {{ $t("profile.voucher.redeem_popup.subheadline") }}
        </h3>
        <div id="reward-container" class="dead-center">
          <EarningShield
            v-if="shownReward.asset"
            :earning="rewardEarning(shownReward)"
            size="lg"
            ><template v-slot:earning-amount-or-text>{{
              rewardAmount(shownReward)
            }}</template></EarningShield
          >
        </div>
        <button
          class="ok-button-green round-new w-50 lg to-center-abs"
          @click="$bvModal.hide('modal-voucher-redeemed')"
        >
          {{ $t("dailylogin.button_collect") }}
        </button></template
      >
    </b-modal>
  </div>
</template>

<script>
import EarningShield from "@/components/base/earning-shield.vue";
export default {
  name: "ModalVoucherRedeemed",
  components: { EarningShield },
  props: {
    rewards: {
      type: Array,
      required: true,
    },
    shownReward: {
      type: Object,
      default: () => {},
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    rewardEarning(reward) {
      if (!reward.asset) return;
      if (reward.asset.endsWith("Currency")) {
        return reward.type;
      } else if (reward.asset.endsWith("Card")) {
        return "card";
      } else if (reward.asset.endsWith("Timer")) {
        return "xpboost";
      } else if (reward.asset.endsWith("Elixir")) {
        return "elixir";
      }
    },
    rewardAmount(reward) {
      if (!reward.amount) return;
      if (this.rewardEarning(reward) === "xpboost") {
        let boostInMinutes = reward.amount / 60;
        if (boostInMinutes <= 1) {
          return boostInMinutes + " " + this.$t("time.minute>one");
        } else if (boostInMinutes > 1) {
          return boostInMinutes + " " + this.$t("time.minute>other");
        }
      } else {
        return this.formatNumber(reward.amount);
      }
    },
  },
};
</script>
<style lang="scss">
#modal-voucher-redeemed {
  .modal-dialog {
    height: 540px;
    h1 {
      color: #fff8b7;
      font-size: 32px;
    }
  }

  #reward-container {
    width: 200px;
    height: 246px;
  }
  .ok-button-green {
    top: 400px;
  }
}
</style>
