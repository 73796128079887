<template>
  <b-col id="voucher-container" cols="12">
    <div id="voucher-text-row" class="w-50 mx-auto mt-3 d-flex flex-column">
      <p class="uppercase-subheadline m-0">
        {{ $t("profile.voucher.title") }}
      </p>
      <p class="text-white medium">
        {{ $t("profile.voucher.sub_title") }}
      </p>
    </div>
    <b-row no-gutters class="pb-5" align-v="center">
      <b-col cols="6" class="p-0"><div id="voucher-img"></div></b-col
      ><b-col
        id="input-col"
        cols="6"
        class="d-flex flex-column justify-content-center"
        ><form class="w-75" @submit.prevent="handleRedeem">
          <label class="dark-label" for="reset-email-input">{{
            $t("profile.voucher.label")
          }}</label
          ><input
            id="voucher-input"
            v-model="voucherCode"
            class="form-control dark mb-3"
            required
          /><button
            type="submit"
            class="ok-button-green round-new w-50 lg"
            :disabled="!validVoucher"
            style="margin-left: 25%"
          >
            {{ $t("profile.voucher.redeem") }}
          </button>
        </form></b-col
      ></b-row
    ><ModalVoucherRedeemed :rewards="rewards" :shown-reward="shownReward" />
  </b-col>
</template>

<script>
import { redeemVoucher } from "@/API/profile.js";
import ModalVoucherRedeemed from "@/components/profile/modal-voucher-redeemed";

export default {
  name: "VoucherContainer",
  components: {
    ModalVoucherRedeemed,
  },

  data() {
    return {
      voucherCode: "",
      intervalId: null,
      rewards: [
        { type: null, amount: 1, asset: "App\\Models\\Asset\\Card" },
        { type: "gold", amount: 100, asset: "App\\Models\\Asset\\Currency" },
        { type: "crown", amount: 10, asset: "App\\Models\\Asset\\Currency" },
        { type: null, amount: 10, asset: "App\\Models\\Asset\\Timer" },
      ],
      shownReward: {},
    };
  },
  computed: {
    validVoucher() {
      return this.formattedVoucher.length <= 16;
      // let re = /(^([A-Z0-9]{4}-){3}[A-Z0-9]{4}$)/;
      // let reAlt = /(^([A-Z0-9]{4}){4}$)/;
      // return re.test(this.voucherCode) || reAlt.test(this.voucherCode);
    },
    formattedVoucher() {
      let formattedString = this.voucherCode.split("-").join("");
      return formattedString;
    },
  },
  mounted() {
    this.$store.commit("setIsPageLoading", false);
  },
  methods: {
    // testModal() {
    //   this.shownReward = this.rewards[0];
    //   this.flipThroughRewards();
    //   this.showModal();
    // },
    showModal() {
      this.$bvModal.show("modal-voucher-redeemed");
    },
    handleRedeem() {
      redeemVoucher(this.formattedVoucher)
        .then((res) => {
          this.clearInput();
          this.rewards = res.data.data.voucher.rewards;
          this.shownReward = this.rewards[0];
          this.flipThroughRewards();
          this.showModal();
        })
        .catch((error) => {
          this.clearInput();
          if (error.data.message) {
            this.$store.commit("popups/setAlertBannerContent", {
              alertText: error.data.message,
              alertHeading: this.$t("alerts.title.error"),
              variant: "danger",
              type: "danger",
            });
          } else {
            this.displayErrorMessage();
          }
        });
    },
    clearInput() {
      this.voucherCode = "";
    },
    flipThroughRewards() {
      let count = 0;
      this.intervalId = setInterval(() => {
        // reset index to 0 to create infinite loop
        count = (count + 1) % this.rewards.length;
        this.shownReward = this.rewards[count];
      }, 2000);
    },
  },
};
</script>
<style lang="scss">
#voucher-container {
  background-color: $dark-blue-06;
  #voucher-text-row {
    .text-white {
      font-size: 21px;
      text-shadow: 0 0 13px rgba(77, 229, 255, 0.6),
        0 0 6px rgba(77, 229, 255, 0.6), 0 0 3px rgba(0, 94, 110, 0.8);
    }
  }
  #voucher-img {
    width: 350px;
    height: 268px;
    background: url(~@/assets/img/profile/voucher-chest.png) center/100% 100%
      no-repeat;
    margin-left: auto;
  }
}
</style>
