<template>
  <router-view :stats="stats" @update="$store.dispatch('user/updateUser')" />
</template>

<script>
import getPublicUserInfos from "@/mixins/getPublicUserInfos.js";
export default {
  name: "ProfilePage",
  mixins: [getPublicUserInfos],
  data() {
    return {
      userId: null,
      loading: true,
      newEmailAdress: "",
      newUsername: "",
      errorMessage: "",
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/currentUser"];
    },
  },
  beforeCreate: function () {
    this.$store.commit("setIsPageLoading", true);
  },
  created() {
    this.userId = this.$store.getters["user/currentUser"].id;
  },
  mounted() {
    this.getUserStats(this.userId);
    this.$store.dispatch("user/updateUser").then(() => {
      this.$store.commit("setIsPageLoading", false);
    });
  },
};
</script>
<style lang="scss"></style>
